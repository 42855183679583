<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import '@/assets/iconfont/iconfont.css';
export default {
  name: 'App',
}
</script>

<style>
  @import "assets/css/normalize.css";
</style>
