<template>
  <div class="header-container">
    <div class="left">
      <span>20-22年</span>
      <span>销售总额</span>
    </div>
    <div class="total">约{{formatMoney(info.amount)}}￥</div>
    <div class="mid">
      <div class="title-container">
        <div class="item">近三年成交项目数<span>{{info.project}}</span></div>
        <div class="item">在进行项目数<span>{{info.project_in}}</span></div>
        <div class="item">意向客户数<span>{{info.intention}}</span></div>
      </div>
    </div>
    <div class="mim-space"></div>
    <div class="right">
      <span style="padding-right: 10px">社会贡献</span>
    </div>
    <div class="energy">
      <div class="item">
        <div class="img-box">
          <img src="../../assets/imgs/icon-cloud.png" alt="">
        </div>
        <div class="count">{{info.co2}} <span>T</span></div>
        <div class="name">CO<sup>2</sup>减碳排</div>
      </div>
      <div class="item">
        <div class="img-box">
          <img src="../../assets/imgs/icon-coal.png" alt="">
        </div>
        <div class="count">{{info.coal}} <span>T</span></div>
        <div class="name">节约煤炭量</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getHeaderInfo} from '@/api/global'
  export default {
    name: "index",
    data() {
      return {
        info: {}
      }
    },
    mounted() {
      this.getInfo()
    },
    methods: {
      formatMoney(amount) {
        if (amount && typeof +amount == 'number') {
          amount = +amount;
          let res = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(amount);
         return res;
        }
      },
      async getInfo() {
        try{
          const res = await getHeaderInfo();
          this.info = res.data.data;
        } catch (e) {
          console.log(e);
        } finally {
          console.log('finish');
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .header-container{
    width: 100%;
    height: 90px;
    overflow: hidden;
    position: relative;
    .mid{
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 18px;
      z-index: 10;
      width: 928px;
      height: 74px;
      background-image: url("../../assets/imgs/info-title.png");
      .title-container{
        display: flex;
        width: 100%;
        height: 100%;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0 60px;
        box-sizing: border-box;
        .item{
          font-size: 24px;
          color: #ffffff;
          font-weight: bold;
          font-style: oblique;
          background-image:-webkit-linear-gradient(bottom,#548fab,#fff, #fff);
          -webkit-background-clip:text;
          -webkit-text-fill-color:transparent;
          span{
            padding-right: 10px;
            margin-left: 12px;
          }
        }
      }
    }
    .mim-space{
      float: left;
      width: 928px;
      height: 74px;
    }
    .left{
      width: 160px;
      height: 74px;
      text-align: center;
      box-sizing: border-box;
      padding: 10px 0px 10px 28px;
      margin-top: 18px;
      color: #65c6e7;
      font-size: 22px;
      float: left;
      display: flex;
      align-items: center;
      align-content: space-around;
      flex-wrap: wrap;
      margin-right: 25px;
      span{
        width: 100%;
        font-size: 24px;
        color: #ffffff;
        font-weight: bold;
        font-style: oblique;
        background-image:-webkit-linear-gradient(bottom,#548fab,#fff, #fff);
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent;
      }
    }
    .right{
      width: 110px;
      height: 74px;
      text-align: center;
      box-sizing: border-box;
      padding: 10px 0px 10px 0px;
      margin-left: 150px;
      margin-top: 18px;
      color: #65c6e7;
      font-size: 24px;
      float: left;
      display: flex;
      align-items: center;
      align-content: space-around;
      flex-wrap: wrap;
      span{
        width: 100%;
        font-size: 24px;
        color: #ffffff;
        font-weight: bold;
        font-style: oblique;
        background-image:-webkit-linear-gradient(bottom,#548fab,#fff, #fff);
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent;
      }
    }
    .total{
      margin-top: 18px;
      height: 74px;
      line-height: 74px;
      float: left;
      font-size: 30px;
      color: #3edb07;
    }
    .energy{
      margin-left: 35px;
      float: left;
      .item{
        width: 120px;
        text-align: center;
        margin-top: 18px;
        float: left;
        .img-box{
          height: 32px;
          text-align: center;
          margin: 0 auto;
        }
        .count{
          font-size: 17px;
          color: #04ba19;
          span{
            color: #65c6e7;
          }
        }
        .name{
          font-size: 15px;
          color: #65c6e7;
        }
      }
    }
  }
</style>
