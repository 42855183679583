<template>
  <div class="chart-container">
    <div class="chart" ref="chart"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  let chart = null;
  export default {
    name: "index",
    props: {
      xData: {
        type: Array,
        default() {
          return ['2020', '2021', '2022']
        }
      },
      yData: {
        type: Array,
        default() {
          return [
            [1360,1320, 732, 701, 987, 150],
            [1360,1320, 732, 701, 987, 150],
            [100,900, 300, 860, 346, 876],
          ]
        }
      },
    },
    data() {
      const data = this.yData[0];
      const data2 = this.yData[1];
      const data3 = this.yData[2];
      const sideData = data.map(item => item + 90);
      const sideData2 = data2.map(item => item + 90);
      const sideData3 = data3.map(item => item + 90);
      var colorArr = ["#01ec7e", "#1fc260", "#19d0b7"];
      var colorArr2 = ["#01ecd3", "#1fc2a9", "#19d0b7"];
      var colorArr3 = ["#019cf5", "#2190c5", "#1489c6"];
      var color = {
        type: "linear",
        x: 1,
        x2: 0,
        y: 0,
        y2: 0,
        colorStops:  [
          {
            offset: 0,
            color: colorArr[0],
          },
          {
            offset: 1,
            color: colorArr[1],
          },
        ],
      };
      var color2 = {
        type: "linear",
        x: 1,
        x2: 0,
        y: 0,
        y2: 0,
        colorStops:  [
          {
            offset: 0,
            color: colorArr2[0],
          },
          {
            offset: 1,
            color: colorArr2[1],
          },
        ],
      };
      var color3 = {
        type: "linear",
        x: 1,
        x2: 0,
        y: 0,
        y2: 0,
        colorStops:  [
          {
            offset: 0,
            color: colorArr3[0],
          },
          {
            offset: 1,
            color: colorArr3[1],
          },
        ],
      };
      var barWidth = 9;
      return {
        option: {
          graphic: {
            type: 'text',
            left: '8%',
            bottom: '3%',
            style: {
              fill: '#eff7ff',
              text: '金额（千万）',
              fontSize: '15px'
            }
          },
          tooltip: {
            trigger: 'item',
          },
          grid: {
            left: '150px',
            top: '20px',
            right: '30px',
            bottom: '30px',
          },
          dataZoom: [
            {
              type: 'inside',
            }
          ],
          xAxis: [
            {
              type: 'value',
              axisLabel: {
                // interval: 0,
                // rotate: 45,
                textStyle: {
                  color: '#fff',
                  fontSize: 16
                },
                // formatter(text) {
                //   let _text = text
                //   _text = _text.replace('、', '');
                //   if (_text.length >= 4) {
                //     _text = _text.substring(0, 4) + '\n' + _text.substring(4, _text.length);
                //   }
                //   return _text
                // }
              },
              axisLine: {
                show: true, //显示x轴
                lineStyle:{
                  color: "rgba(255,255,255,0.2)"
                }
              },
              axisTick: {
                show: true //显示刻度
              },
              boundaryGap: true,
              splitLine: {
                show: true,
                width: 0.08,
                lineStyle: {
                  type: "solid",
                  color: "rgba(255,255,255,0.2)"
                }
              },
            }
          ],
          yAxis: [
            {
              type: 'category',
              data: this.xData,
              splitLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(255,255,255,0.2)',
                  type: 'solid'
                }
              },
              axisTick: {
                show: true,
              },
              axisLine: {
                show: true,
                lineStyle:{
                  color: "rgba(255,255,255,0.2)"
                }
              },
              axisLabel: {
                textStyle: {
                  color: '#fff',
                  fontSize: 16
                },
              }
            }
          ],
          series: [
            {
              z: 1,
              name: '2020',
              type: "bar",
              barWidth: barWidth,
              barGap: '1%',
              data: data,
              itemStyle: {
                normal: {
                  color: color,
                  shadowColor: colorArr[0],
                  shadowBlur: 5
                },
              },
            },
            // {
            //   z: 3,
            //   name: '2020',
            //   type: "pictorialBar",
            //   symbolPosition: "end",
            //   data: data,
            //   symbol: "diamond",
            //   symbolOffset: ['-55%', "-50%"],
            //   symbolSize: [barWidth, barWidth * 0.5],
            //   itemStyle: {
            //     normal: {
            //       borderWidth: 2,
            //       color: colorArr[2]
            //     },
            //   },
            //   tooltip: {
            //     show: true,
            //   },
            // },
            {
              z: 4,
              name: '2021',
              type: "bar",
              barWidth: barWidth,
              barGap: '1%',
              data: data2,
              itemStyle: {
                normal: {
                  color: color2,
                  shadowColor: colorArr2[0],
                  shadowBlur: 5
                },
              },
            },
            // {
            //   z: 6,
            //   name: '2021',
            //   type: "pictorialBar",
            //   symbolPosition: "end",
            //   data: data2,
            //   symbol: "diamond",
            //   symbolOffset: ["5%", "-50%"],
            //   symbolSize: [barWidth, barWidth * 0.5],
            //   itemStyle: {
            //     normal: {
            //       borderWidth: 2,
            //       color: colorArr2[2]
            //     },
            //   },
            //   tooltip: {
            //     show: true,
            //   },
            // },
            {
              z: 7,
              name: '2022',
              type: "bar",
              barWidth: barWidth,
              barGap: '1%',
              data: data3,
              itemStyle: {
                normal: {
                  color: color3,
                  shadowColor: colorArr3[0],
                  shadowBlur: 5
                },
              },
            },
            // {
            //   z: 9,
            //   name: '2022',
            //   type: "pictorialBar",
            //   symbolPosition: "end",
            //   data: data3,
            //   symbol: "diamond",
            //   symbolOffset: ["55%", "-50%"],
            //   symbolSize: [barWidth, barWidth * 0.5],
            //   itemStyle: {
            //     normal: {
            //       borderWidth: 2,
            //       color: colorArr3[2]
            //     },
            //   },
            //   tooltip: {
            //     show: true,
            //   },
            // },
          ]
        }
      }
    },
    methods: {
      initChart() {
        chart = echarts.init(this.$refs.chart)
        chart.setOption(this.option)
      },
      changeSize () {
        chart.resize()
      }
    },
    mounted () {
      this.initChart()
      //根据窗口变化自动调节图表大小
      const that = this
      window.onresize = function () {
        that.changeSize()
      }
    },
  }
</script>

<style scoped lang="scss">
  .chart-container{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 20;
    .chart{
      width: 100%;
      height: 100%;
    }
  }
</style>
