<template>
  <div class="page-container">
    <img v-if="list.length" :src="'http://php.jxuemoxingzhanshi.com/' + list[cur].img" alt="">
  </div>
</template>
<script>
  import {getList} from '@/api/case'
  import {mapState, mapGetters, mapActions} from 'vuex';
  export default {
    name: 'MediaInfo',
    computed: {
      ...mapState({ count: state => state.demo.count, }),
    },
    watch: {
      count: {
        handler(nv, ov) {
          if (nv > ov) {
            if (this.cur >= this.list.length -1) {
              this.cur = 0
            } else {
              this.cur = this.cur + 1
            }
          } else if (nv < ov) {
            if (this.cur <= 0) {
              this.cur = this.list.length - 1
            } else {
              this.cur = this.cur - 1
            }
          }
        },
        deep: true,
        immediate: true
      }
    },
    data() {
      return {
        idx: -1,
        cur: 0,
        show: true,
        list: [],
        typeList: [
          '雪馆',
          '食品加工',
          '陆港冷库',
          '空港冷库',
          '菌菇养殖',
          '海港冷库',
          '果蔬气调',
          '工业门业',
          '工业厂房'
        ],
      }
    },
    methods: {
      async getData() {
        try{
          const res = await getList({type: this.typeList[this.idx]});
          this.list = res.data.data.data;
        } catch (e) {
          console.log(e);
        } finally {
          console.log('finish');
        }
      }
    },

    mounted() {
      this.idx = +this.$route.query.idx;
      this.getData();
    }
  }
</script>

<style lang='scss' scoped>
  .page-container {
    background-color: #000;
    background-size: cover;
    background-repeat: no-repeat;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: 0.3s ease;
      /*opacity: 0;*/
    }
    .show{
      opacity: 1;
    }
    .hide{
      opacity: 0;
    }
  }
</style>
