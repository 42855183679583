<template>
  <div class="page-container">
    <InfoHeader></InfoHeader>
    <InfoChina></InfoChina>
    <div class="charts-container">
      <div class="chart-item">
        <div class="chart-title">
          <div class="area-name">{{curArea}}-客户行业分类销售数据</div>
          <div class="area-count">总金额 <span>约{{punterTotal}}¥</span></div>
        </div>
        <div class="area-content" v-if="punterData.length">
          <HighPie1 class="pie3d" unit="￥" :data="punterData"></HighPie1>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-title">
          <div class="area-name">{{curArea}}-业主行业分类销售数据</div>
          <div class="area-count">总金额 <span>约{{ownerTotal}}¥</span></div>
        </div>
        <div class="area-content" v-if="ownerData.length">
          <HighPie2 class="pie3d" unit="￥" :data="ownerData"></HighPie2>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-title">
          <div class="area-name">全国-产品分类销售数量</div>
          <div class="area-count">总金额 <span>约{{countryTotal}}¥</span></div>
        </div>
        <div class="area-content" v-if="countryDataX.length">
          <Bar3 :yData="countryDataY" :xData="countryDataX"></Bar3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getPunter, getOwner, getCountry} from '@/api/apply'
  import Bar3 from '@/components/bar3'
  import InfoHeader from '@/components/infoHeader'
  import InfoChina from '@/components/infoChina'
  import HighPie1 from '@/components/highPie1'
  import HighPie2 from '@/components/highPie2'
  export default {
    name: 'chart',
    components: {
      InfoHeader,
      InfoChina,
      Bar3,
      HighPie1,
      HighPie2
    },
    data() {
      return {
        punterData: [],
        punterTotal: 0,
        ownerData: [],
        ownerTotal: 0,
        countryDataX: [],
        countryDataY: [],
        countryTotal: 0,
        curArea: localStorage.getItem('current') || '江苏'
      }
    },
    methods: {
      async handleGetPunter() {
        try{
          const res = await getPunter({area: localStorage.getItem('current') || '江苏', pageSize: 9999});
          let punterData = []
          let punterTotal = 0;
          let punterDataMap = {};

          res.data.data.data.forEach(item => {
            punterData.push(item)
            punterTotal = punterTotal + +item.value
            if (punterDataMap[item.type]) {
              punterDataMap[item.type] += +item.value;
            } else {
              punterDataMap[item.type] = +item.value
            }
          })
          this.punterTotal = Math.round(punterTotal);
          let _punterData = []
          for(let key in punterDataMap) {
            _punterData.push({
              type: key,
              value: punterDataMap[key]
            })
          }
          this.punterData = _punterData.map(item => (
            {
              name: item.type,
              y: +item.value.toFixed(0),
              h: Math.round((item.value / punterTotal) * 100)
            }
          ));
        } catch (e) {
          console.log(e);
        } finally {
          console.log('finish');
        }
      },
      async handleGetOwner() {
        try{
          const res = await getOwner({area: localStorage.getItem('current') || '江苏', pageSize: 9999});
          let ownerData = []
          let ownerTotal = 0;
          let ownerDataMap = {};
          res.data.data.data.forEach(item => {
            ownerData.push(item)
            ownerTotal = ownerTotal + +item.value
            if (ownerDataMap[item.type]) {
              ownerDataMap[item.type] += +item.value;
            } else {
              ownerDataMap[item.type] = +item.value
            }
          })
          let _ownerData = []
          for(let key in ownerDataMap) {
            _ownerData.push({
              type: key,
              value: ownerDataMap[key]
            })
          }
          this.ownerTotal = Math.round(ownerTotal);
          this.ownerData = _ownerData.map(item => (
            {
              name: item.type,
              y: +item.value.toFixed(0),
              h: Math.round((item.value / ownerTotal) * 100)
            }
          ));
        } catch (e) {
          console.log(e);
        } finally {
          console.log('finish');
        }
      },
      async handleGetCountry() {
        try{
          const res = await getCountry({ pageSize: 9999});
          let amountData = {};
          res.data.data.data.forEach(item => {
            if (!amountData[item.type]) amountData[item.type] = {}
            amountData[item.type][item.year] = Math.round(+item.value);
          })
          let countryDataX = [];
          let countryDataY = [];
          let countryTotal = 0;

            function objectOrder(obj) {//排序的函数
              var newkey = Object.keys(obj).sort() //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
              var newObj = {};//创建一个新的对象，用于存放排好序的键值对
              for (var i = 0; i < newkey.length; i++) {//遍历newkey数组
                newObj[newkey[i]] = obj[newkey[i]];//向新创建的对象中按照排好的顺序依次增加键值对
              }
              return newObj;//返回排好序的新对象
            }

            for(let key in objectOrder(amountData)) {
            countryDataX.push(key);
            let idx = 0;
            for (let key2 in amountData[key]) {
              if (!countryDataY[idx]) countryDataY[idx] = []
              countryDataY[idx].push(amountData[key][key2] / 10000000);
              countryTotal += amountData[key][key2]
              idx += 1;
            }
          }

          this.countryDataX = countryDataX
          this.countryDataY = countryDataY
          this.countryTotal = countryTotal;

        } catch (e) {
          console.log(e);
        } finally {
          console.log('finish');
        }
      }
    },
    mounted() {
      this.handleGetPunter();
      this.handleGetOwner();
      this.handleGetCountry();
    }
  }
</script>

<style lang='scss' scoped>
  .page-container {
    background-image: url("../../assets/imgs/info-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    .charts-container{
      padding-top: 35px;
      width: calc(1920px * 0.95);
      margin-left:calc(1920px * 0.025);
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .chart-title{
        box-sizing: border-box;
        width: 100%;
        height: 44px;
        border: 1px solid #257fbe;
        box-shadow: 0 0 26px 5px rgba(#257fbe, 0.4) inset;
        padding: 0 15px;
        line-height: 42px;
        display: flex;
        justify-content: space-between;
        .area-name{
          font-size: 20px;
          color: #65c6e7;
        }
        .area-count{
          font-size: 18px;
          color: #65c6e7;
          span{
            color: #3edb07;
          }
        }
      }
      .area-content{
        width: 100%;
        height: calc(1080px - 44px - 35px - 475.2px - 90px - 66px - 35px);
        .pie3d{
          position: relative;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
      .chart-item{
        width: calc(1920px * 0.31);
        &:nth-child(2) {
          width: calc(1920px * 0.31);
        }
        &:nth-child(3) {
          width: calc(1920px * 0.31);
        }
      }
    }
  }
</style>
