import Vue from "vue";
import VueRouter from "vue-router";
// import Demo from "@/pages/demo";
import Area from '@/pages/area'
import Apply from '@/pages/apply'
import Category from '@/pages/category'
import Media from '@/pages/media'
import MediaInfo from '@/pages/media/info'
import Login from "@/pages/login";
import Index from "@/pages/index";
import Case from "@/pages/case";
import CaseInfo from "@/pages/case/info";
import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "login",
        component: Layout,
        children: [
            {
                path: "/login",
                name: "login",
                component: Login
            },
            {
                path: "/area",
                name: "area",
                component: Area
            },
            {
                path: "/apply",
                name: "apply",
                component: Apply
            },
            {
                path: "/category",
                name: "category",
                component: Category
            },
            {
                path: "/media",
                name: "media",
                component: Media
            },
            {
                path: "/mediaInfo",
                name: "mediaInfo",
                component: MediaInfo
            },
            {
                path: "/case",
                name: "case",
                component: Case
            },
            {
                path: "/caseInfo",
                name: "caseInfo",
                component: CaseInfo
            },
            {
                path: "/index",
                name: "index",
                component: Index
            },
        ]
    }
];

export default new VueRouter({
    routes,
    mode: 'history'
});
