<template>
  <div class="area-modal" :class="position">
    <img class="modal-shape" src="../../assets/imgs/modal-shape.png" alt="">
    <div class="corner lt"></div>
    <div class="corner lb"></div>
    <div class="name">省份名称：{{name}}</div>
    <div class="info">
      <span>今日成交量：{{info.number}}</span>
      <span>今日成交金额：{{info.money}}</span>
      <span>项目类别：{{info.type}}</span>
      <span>意向客户数：{{info.intention}}</span>
    </div>
  </div>
</template>

<script>
  import {getDayInfo} from '@/api/global'

  export default {
    name: "index",
    props: {
      position: {
        type: String,
        default: 'left'
      }
    },
    data() {
        return {
          info: {},
          name: localStorage.getItem('current') || '江苏'
        }
    },
    methods: {
      async getInfo() {
        try{
          const res = await getDayInfo({area: this.name});
          this.info = res.data.data;
        } catch (e) {
          console.log(e);
        } finally {
          console.log('finish');
        }
      },
    },
    mounted() {
      this.getInfo();
    }
  }
</script>

<style scoped lang="scss">
  .area-modal{
    width: 271px;
    height: 198px;
    background-image: url('../../assets/imgs/modal-bg.png');
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(1.2);
    z-index: 40;
    color: #fff;
    border: 1px solid rgba(255,255,255,0.2);
    box-sizing: border-box;
    padding-left: 26px;
    .modal-shape{
      position: absolute;
      bottom: 0;
      left: 60px;
      z-index: 10;
    }
    .name{
      width: 184px;
      height: 60px;
      line-height: 60px;
      font-weight: bold;
      font-size: 18px;
      border-bottom: 1px solid #ff961b;
    }
    .info{
      font-size: 16px;
      color: #c1d5e7;
      padding-top: 12px;
      line-height: 24px;
      span{
        width: 100%;
        display: block;
        margin-bottom: 3px;
      }
    }
    .corner{
      width: 12px;
      height: 12px;
      border: 2px solid #fff;
      box-sizing: border-box;
      position: absolute;
      pointer-events: none;
      z-index: 20;
    }
    .lt{
      border-right: none;
      border-bottom: none;
      top: -1px;
      left: -1px;
    }
    .lb{
      border-right: none;
      border-top: none;
      bottom: -1px;
      left: -1px;
    }
  }
  .right{
    right: calc(25% - 271px / 2);
  }
  .left{
    left: calc(25% - 271px / 2);
  }
</style>
