<template>
  <div class="chart-container">
    <div class="chart" ref="chart"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  let chart = null;
  export default {
    name: "index",
    props: {
      xData: {
        type: Array,
        default() {
          return []
        }
      },
      yData: {
        type: Array,
        default() {
          return []
        }
      },
    },
    data() {
      return {
        option: {
          tooltip: {
            trigger: 'item',
          },
          legend: {
            align: 'left',
            right: '0%',
            top: '5%',
            textStyle: {
              fontSize: '15px',
              color: '#eff7ff'
            },
            icon: 'rect',
            itemWidth: 14,
            itemHeight: 8,
            itemGap: 22
          },
          graphic: {
            type: 'text',
            left: '0%',
            top: '5%',
            style: {
              fill: '#eff7ff',
              text: '单位：万平方米',
              fontSize: '15px'
            }
          },

          grid: {
            left: '40px',
            top: '50px',
            right: '10px',
            bottom: '35px',
          },
          dataZoom: [
            {
              type: 'inside',
            }
          ],
          xAxis: [
            {
              data: this.xData,
              axisLabel: {
                interval: 0,
                textStyle: {
                  color: '#fff',
                  fontSize: 15
                },
              },
              axisLine: {
                show: false, //显示x轴
                lineStyle:{
                  color: "rgba(255,255,255,0.2)"
                }
              },
              axisTick: {
                show: false //显示刻度
              },
              boundaryGap: true,
              splitLine: {
                show: false,
                width: 0.08,
                lineStyle: {
                  type: "solid",
                  color: "rgba(255,255,255,0.2)"
                }
              },
            }
          ],
          yAxis: [
            {
              splitLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(255,255,255,0.2)',
                  type: 'solid'
                }
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
                lineStyle:{
                  color: "rgba(255,255,255,0.2)"
                }
              },
              axisLabel: {
                textStyle: {
                  color: '#fff',
                  fontSize: 15
                },
              }
            }
          ],
          series: []
        }
      }
    },
    methods: {
      genSeries() {
        let names = [2020, 2021, 2022]
        let colors = [
          ['rgba(22,198,196,1)', 'rgba(22,198,196,0)'],
          ['rgba(0,172,251,1)', 'rgba(0,172,251,0)'],
          ['rgba(251,154,93,1)', 'rgba(251,154,93,0)'],
        ]
        let series = [];
        names.forEach((item, index) => {
          series.push(
            {
              name: item,
              type: 'bar',
              barWidth: 16,
              barGap: '30%',
              data: this.yData[index],
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(3,36,48,0.62)'
              },
              itemStyle: {
                "normal": {
                  "color": {
                    "x": 0,
                    "y": 0,
                    "x2": 0,
                    "y2": 1,
                    "type": "linear",
                    "global": false,
                    "colorStops": [
                      {
                        "offset": 0,
                        "color": colors[index][0]
                      },
                      {
                        "offset": 1,
                        "color": colors[index][1]
                      }
                    ]
                  }
                }
              },
            },
          )
        })
        this.option.series = series
      },
      initChart() {
        this.genSeries();
        chart = echarts.init(this.$refs.chart)
        chart.setOption(this.option)
      },
      changeSize () {
        chart.resize()
      }
    },
    mounted () {
      this.initChart()
      //根据窗口变化自动调节图表大小
      const that = this
      window.onresize = function () {
        that.changeSize()
      }
    },
  }
</script>

<style scoped lang="scss">
  .chart-container{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 20;
    .chart{
      width: 100%;
      height: 100%;
    }
  }
</style>
