<template>
    <div class="page-container">
        <div class="router-container">
            <router-view></router-view>
        </div>
        <div class="bottom" v-if="curName !== 'login' && curName !== 'mediaInfo' && curName !== 'caseInfo'">
            <div v-for="item in navList" :key="item.name" class="item" :class="curName == item.name ? 'item-active': ''" :style="{width: item.title == '首页' ? '145px' : '193px'}" @click="goPage(item.name)" >
                <div class="top-shape"></div>
                <div class="left-shape"></div>
                <div class="item-content">
                    <div class="title-1">
                        <span>{{item.en.toUpperCase()}}</span>
                    </div>
                    <div class="bottom-shape">
                        <img src="../assets/imgs/bottom-shape.png" alt="">
                    </div>
                </div>
                <div class="item-name">{{item.title}}</div>
            </div>
            <div class="info">
                <div class="time">
                    <div class="hour">{{curDate}}</div>
                    <div class="date">{{curDay}}</div>
                </div>
                <div class="split"></div>
                <div class="temperature">
                    {{weather.temperature}}℃
                </div>
                <div class="split"></div>
                <div class="temperature">
                    <i :class="weatherMap[weather.weather]" :title="weather.weather"></i>
                </div>
                <div class="split"></div>
                <div class="pm">
                    <div class="pm-title">风向</div>
                    <div class="pm-value">{{weather.winddirection}}</div>
                </div>
                <div class="split"></div>
                <div class="pm">
                    <div class="pm-title">风力</div>
                    <div class="pm-value">{{weather.windpower}}</div>
                </div>
                <div class="split"></div>
                <div class="pm">
                    <div class="pm-title">湿度</div>
                    <div class="pm-value">{{weather.humidity}}</div>
                </div>
            </div>
        </div>
        <div class="bottom" v-if="curName === 'mediaInfo'">
            <div class="item item-active" style="width: 144px" @click="goPage('media')" >
                <div class="top-shape"></div>
                <div class="left-shape"></div>
                <div class="item-content">
                    <div class="title-1">
                        <span>BACK</span>
                    </div>
                    <div class="bottom-shape">
                        <img src="../assets/imgs/bottom-shape.png" alt="">
                    </div>
                </div>
                <div class="item-name">返回</div>
            </div>
            <div class="info">
                <div class="time">
                    <div class="hour">{{curDate}}</div>
                    <div class="date">{{curDay}}</div>
                </div>
                <div class="split"></div>
                <div class="temperature">
                    {{weather.temperature}}℃
                </div>
                <div class="split"></div>
                <div class="temperature">
                    <i :class="weatherMap[weather.weather]" :title="weather.weather"></i>
                </div>
                <div class="split"></div>
                <div class="pm">
                    <div class="pm-title">风向</div>
                    <div class="pm-value">{{weather.winddirection}}</div>
                </div>
                <div class="split"></div>
                <div class="pm">
                    <div class="pm-title">风力</div>
                    <div class="pm-value">{{weather.windpower}}</div>
                </div>
                <div class="split"></div>
                <div class="pm">
                    <div class="pm-title">湿度</div>
                    <div class="pm-value">{{weather.humidity}}</div>
                </div>
            </div>
        </div>
        <div class="bottom" v-if="curName === 'caseInfo'">
            <div class="item item-active" style="width: 144px" @click="goPage('case')" >
                <div class="top-shape"></div>
                <div class="left-shape"></div>
                <div class="item-content">
                    <div class="title-1">
                        <span>BACK</span>
                    </div>
                    <div class="bottom-shape">
                        <img src="../assets/imgs/bottom-shape.png" alt="">
                    </div>
                </div>
                <div class="item-name">返回</div>
            </div>
            <div class="item" style="width: 260px" @click="goPrevPage()" >
                <div class="top-shape"></div>
                <div class="left-shape"></div>
                <div class="item-content">
                    <div class="title-1">
                        <span>PREV</span>
                    </div>
                    <div class="bottom-shape">
                        <img src="../assets/imgs/bottom-shape.png" alt="">
                    </div>
                </div>
                <div class="item-name">上一页</div>
            </div>
            <div class="item" style="width: 260px" @click="goNextPage()" >
                <div class="top-shape"></div>
                <div class="left-shape"></div>
                <div class="item-content">
                    <div class="title-1">
                        <span>NEXT</span>
                    </div>
                    <div class="bottom-shape">
                        <img src="../assets/imgs/bottom-shape.png" alt="">
                    </div>
                </div>
                <div class="item-name">下一页</div>
            </div>
            <div class="info">
                <div class="time">
                    <div class="hour">{{curDate}}</div>
                    <div class="date">{{curDay}}</div>
                </div>
                <div class="split"></div>
                <div class="temperature">
                    {{weather.temperature}}℃
                </div>
                <div class="split"></div>
                <div class="temperature">
                    <i :class="weatherMap[weather.weather]" :title="weather.weather"></i>
                </div>
                <div class="split"></div>
                <div class="pm">
                    <div class="pm-title">风向</div>
                    <div class="pm-value">{{weather.winddirection}}</div>
                </div>
                <div class="split"></div>
                <div class="pm">
                    <div class="pm-title">风力</div>
                    <div class="pm-value">{{weather.windpower}}</div>
                </div>
                <div class="split"></div>
                <div class="pm">
                    <div class="pm-title">湿度</div>
                    <div class="pm-value">{{weather.humidity}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapActions} from 'vuex';
    import moment from 'moment'
    import axios from "axios"
    let TimeIntervel = null;
    let weatherMap = {
        晴: 'iconfont icon-qingtian',
        少云: 'iconfont icon-duoyunzhuanqing',
        晴间多云: 'iconfont icon-qingzhuanduoyun',
        多云: 'iconfont icon-qingzhuanduoyun',
        阴: 'iconfont icon-yintian',
        有风: 'iconfont icon-feng',
        平静: 'iconfont icon-feng',
        微风: 'iconfont icon-feng',
        和风: 'iconfont icon-feng',
        清风: 'iconfont icon-feng',
        '强风/劲风': 'iconfont icon-feng',
        疾风: 'iconfont icon-feng',
        大风: 'iconfont icon-feng',
        烈风: 'iconfont icon-feng',
        风暴: 'iconfont icon-feng',
        狂爆风: 'iconfont icon-feng',
        飓风: 'iconfont icon-feng',
        热带风暴: 'iconfont icon-feng',
        霾: 'iconfont icon-youwu',
        中度霾: 'iconfont icon-youwu',
        重度霾: 'iconfont icon-youwu',
        严重霾: 'iconfont icon-youwu',
        阵雨: 'iconfont icon-zhenyu',
        雷阵雨: 'iconfont icon-leizhenyu',
        雷阵雨并伴有冰雹: 'iconfont icon-bingbao',
        小雨: 'iconfont icon-xiaoyu',
        中雨: 'iconfont icon-zhongyu',
        大雨: 'iconfont icon-dayu',
        暴雨: 'iconfont icon-baoyu',
        大暴雨: 'iconfont icon-baoyu',
        特大暴雨: 'iconfont icon-baoyu',
        强阵雨: 'iconfont icon-zhenyu',
        强雷阵雨: 'iconfont icon-leizhenyu',
        极端降雨: 'iconfont icon-baoyu',
        '毛毛雨/细雨': 'iconfont icon-xiaoyu',
        雨: 'iconfont icon-xiaoyu',
        '小雨-中雨': 'iconfont icon-xiaoyu',
        '中雨-大雨': 'iconfont icon-zhongyu',
        '大雨-暴雨': 'iconfont icon-dayu',
        '暴雨-大暴雨': 'iconfont icon-baoyu',
        '大暴雨-特大暴雨': 'iconfont icon-baoyu',
        雨雪天气: 'iconfont icon-yujiaxue',
        雨夹雪: 'iconfont icon-yujiaxue',
        阵雨夹雪: 'iconfont icon-yujiaxue',
        冻雨: 'iconfont icon-yujiaxue',
        雪: 'iconfont icon-xiaoxue',
        阵雪: 'iconfont icon-xiaoxue',
        小雪: 'iconfont icon-xiaoxue',
        中雪: 'iconfont icon-zhongxue',
        大雪: 'iconfont icon-daxue',
        暴雪: 'iconfont icon-baoxue',
        '小雪-中雪': 'iconfont icon-xiaoxue',
        '中雪-大雪': 'iconfont icon-zhongxue',
        '大雪-暴雪': 'iconfont icon-daxue',
        浮尘: 'iconfont icon-shachenbao',
        扬沙: 'iconfont icon-shachenbao',
        沙尘暴: 'iconfont icon-shachenbao',
        强沙尘暴: 'iconfont icon-shachenbao',
        龙卷风: 'iconfont icon-feng',
        雾: 'iconfont icon-youwu',
        浓雾: 'iconfont icon-youwu',
        强浓雾: 'iconfont icon-youwu',
        轻雾: 'iconfont icon-youwu',
        大雾: 'iconfont icon-youwu',
        特强浓雾: 'iconfont icon-youwu',
        热: 'iconfont icon-qingtian',
        冷: 'iconfont icon-yintian',
        未知: 'iconfont icon-qingtian',
    };
    export default {
        name: "index",
        data() {
            return {
                navList: [
                    {title: '首页', name: 'index', en: 'HOME'},
                    {title: '销售区域', name: 'area', en: 'Area'},
                    {title: '应用领域', name: 'apply', en: 'apply'},
                    {title: '销售品类', name: 'category', en: 'category'},
                    {title: '产品动画', name: 'media', en: 'video'},
                    {title: '项目案例', name: 'case', en: 'case'},
                ],
                weather: '',
                curName: '',
                curDate: '',
                curDay:'',
                weatherMap
            }
        },
        watch: {
            $route: {
                handler(nv) {
                    this.curName = nv.name
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
            this.getTime()
            TimeIntervel = setInterval(() => {
                this.getTime()
            }, 1000)
            this.getWeather();
        },
        methods: {
            ...mapActions({ increment: 'demo/increment', decrement: 'demo/decrement', }),
            goPrevPage() {
              this.decrement()
            },
            goNextPage() {
                this.increment()
            },
            getTime() {
                this.curDate = moment().format('HH:mm')
                this.curDay = moment().format('YYYY.MM.DD')
            },
            goPage(name) {
                this.$router.push({name: name})
            },
            getWeather() {
                axios.get('https://restapi.amap.com/v3/weather/weatherInfo?key=4278f3ddbcaf810c2f16ee21db64dff2&city=320400').then(res => {
                    this.weather = res.data.lives[0];
                })
            }
        },
        beforeDestory(){
            clearInterval(TimeIntervel);
            TimeIntervel = null
        }
    }
</script>

<style scoped lang="scss">
    .page-container{
        width: 1920px;
        height: 1080px;
        position: relative;
        .router-container{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 15;
        }
        .bottom{
            width: 100%;
            height: 66px;
            border-top: 2px solid #00556d;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 100;
            background: rgba(#000d20, 0.4);
            box-sizing: border-box;
            padding-left: 15px;
            .info{
                float: right;
                .split{
                    float: left;
                    width: 1px;
                    height: 34px;
                    background: rgba(#ffffff, 0.3);
                    margin-top: 16px;
                }
                .time{
                    float: left;
                    margin-top: 8px;
                    padding: 0 15px;
                    height: 36px;
                    text-align: center;
                    .hour{
                        font-weight: bold;
                        font-size: 21px;
                        color: #fff;
                    }
                    .date{
                        font-size: 13px;
                        color: #969696;
                    }
                }
                .temperature{
                    float: left;
                    padding: 0 30px;
                    font-weight: bold;
                    font-size: 21px;
                    color: #fff;
                    height: 34px;
                    margin-top: 16px;
                    line-height: 34px;
                    i{
                        font-weight: normal;
                        font-size: 25px;
                    }
                }

                .pm{
                    margin-top: 12px;
                    float: left;
                    padding: 0 15px;
                    text-align: right;
                    .pm-title{
                        color: #8c8d90;
                        font-size: 13px;
                    }
                    .pm-value{
                        font-size: 17px;
                        color: #fff;
                        font-weight: bold;
                    }
                }
            }
            .item{
                width: 193px;
                margin-right: 8px;
                margin-top: 12px;
                height: 41px;
                position: relative;
                background: rgba(#043055, 0.5);
                padding-top: 5px;
                overflow: hidden;
                float: left;
                transition: 0.3s ease;
                cursor: pointer;
                .left-shape{
                    width: 3px;
                    height: 31px;
                    background: #1264a0;
                    position: absolute;
                    top: 5px;
                    left: 0px;
                    transition: 0.3s ease;
                    transition-delay: 0.1s;
                }
                .top-shape{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #075289;
                    border-bottom: 3px solid #000e20;
                    transition: 0.3s ease;
                }
                .item-content{
                    float: left;
                    width: 50px;
                    padding-left: 13px;
                    transition: 0.3s ease;
                    .title-1{
                        font-size: 12px;
                        line-height: 14px;
                        height: 14px;
                        span{
                            color: #67b0e5;
                            white-space: nowrap;
                            display: inline-block;
                            transform: scale(0.8);
                            transform-origin: left;
                            transition: 0.3s ease;
                        }
                    }
                    .bottom-shape{
                        width: 5px;
                        height: 5px;
                        padding-top: 4px;
                        img{
                            display: block;
                        }
                    }
                }
                .item-name{
                    float: right;
                    padding-right: 24px;
                    line-height: 36px;
                    font-size: 20px;
                    color: #fff;
                    letter-spacing: 4px;
                }
                &:hover{
                    background: #16999f;
                    .left-shape{
                        background: #24f6f4;
                        width: 9px;
                        top: 0px;
                        height: 100%;
                    }
                    .top-shape{
                        transform: translateY(-5px);
                    }
                    .item-content{
                        padding-left: 18px;
                        .title-1{
                            span{
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .item-active{
                background: #16999f;
                .left-shape{
                    background: #24f6f4;
                    width: 9px;
                    top: 0px;
                    height: 100%;
                }
                .top-shape{
                    transform: translateY(-5px);
                }
                .item-content{
                    padding-left: 18px;
                    .title-1{
                        span{
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
</style>
