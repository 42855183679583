<template>
  <div class="page-container">
    <InfoHeader></InfoHeader>
    <InfoChina></InfoChina>
    <div class="charts-container">
      <div class="chart-item">
        <div class="chart-title">
          <div class="area-name">{{curArea}}-客户数</div>
          <div class="area-count">总客户数 <span>{{customerTotal}}个</span></div>
        </div>
        <div class="area-content" v-if="customerData.length">
          <HighPie2 class="pie3d"
                    :width="400"
                    :height="280"
                    :size="80"
                    type="small"
                    :data="customerData"
                    unit="个"
          ></HighPie2>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-title">
          <div class="area-name">{{curArea}}-合同数</div>
          <div class="area-count">总合同数 <span>{{contractTotal}}份</span></div>
        </div>
        <div class="area-content" v-if="contractData.length">
          <HighPie1 class="pie3d"
                    :width="400"
                    :height="280"
                    :size="70"
                    type="small"
                    unit="份"
                    :color="[
                      ['#4d7faf', '#1880fd'],
                      ['#a58115', '#fcc218'],
                      ['rgba(0,0,0,0.8)', 'rgba(255,255,255,0.3)'],
                    ]"
                    :data="contractData"
          ></HighPie1>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-title">
          <div class="area-name">{{curArea}}-销售金额</div>
          <div class="area-count">总金额 <span>约{{amountTotal}}¥</span></div>
        </div>
        <div class="area-content" v-if="amountDataX.length">
          <Bar1 :yData="amountDataY" :xData="amountDataX"></Bar1>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-title">
          <div class="area-name">{{curArea}}-区域项目数据</div>
        </div>
        <div class="area-content">
          <div class="project-list">
            <div class="table-header">
              <div class="table-cell cell-1">项目名</div>
              <div class="table-cell cell-2">项目金额</div>
            </div>
            <vue-seamless-scroll  :class-option="classOption" :data="listData" class="table-body">
              <div class="table-row" v-for="(item, index) in listData" :key="index">
                <div class="table-cell cell-1">{{item.name}}</div>
                <div class="table-cell cell-2">{{item.value}}</div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getCustomer, getProject, getAmount} from '@/api/area'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  import HighPie1 from '@/components/highPie1'
  import HighPie2 from '@/components/highPie2'
  import Bar1 from '@/components/bar1'
  import InfoHeader from '@/components/infoHeader'
  import InfoChina from '@/components/infoChina'
  export default {
    name: 'chart',
    components: {
      Bar1,
      HighPie1,
      HighPie2,
      InfoHeader,
      vueSeamlessScroll,
      InfoChina
    },
    data() {
      return {
        classOption: {
          step: 0.3
        },
        customerData: [],
        customerTotal: 0,
        contractData: [],
        contractTotal: 0,
        listData: [],
        amountDataX: [],
        amountDataY: [],
        amountTotal: 0,
        curArea: localStorage.getItem('current') || '江苏'
      }
    },
    methods: {
      async handleGetCustomer() {
        try{
          const res = await getCustomer({area: localStorage.getItem('current') || '江苏', pageSize: 9999});
          let customerData = []
          let customerTotal = 0;
          let contractData = []
          let contractTotal = 0;
          res.data.data.data.forEach(item => {
            if (item.type == '合同') {
              contractData.push(item)
              contractTotal = contractTotal + +item.value
            } else if(item.type == '客户') {
              customerData.push(item)
              customerTotal = customerTotal + +item.value
            }
          })
          this.contractTotal = Math.round(contractTotal)
          this.customerTotal = Math.round(customerTotal)
          this.customerData = customerData.map(item => (
            {
              name: item.year,
              y: +item.value,
              h: Math.round((item.value / customerTotal) * 100)
            }
          ));
          this.contractData = contractData.map(item => (
            {
              name: item.year,
              y: +item.value,
              h: Math.round((item.value / contractTotal) * 100)
            }
          ));;
        } catch (e) {
          console.log(e);
        } finally {
          console.log('finish');
        }
      },
      async handleGetProject() {
        try{
          const res = await getProject({area: localStorage.getItem('current') || '江苏', pageSize: 9999});
          this.listData = res.data.data.data
        } catch (e) {
          console.log(e);
        } finally {
          console.log('finish');
        }
      },
      async handleAmount() {
        try{
          const res = await getAmount({area: localStorage.getItem('current') || '江苏', pageSize: 9999});
          let amountData = {};
          res.data.data.data.forEach(item => {
            if (!amountData[item.type]) amountData[item.type] = {}
            amountData[item.type][item.year] = Math.round(+item.value);
          })
          let amountDataX = [];
          let amountDataY = [];
          let amountTotal = 0;
          for(let key in amountData) {
            amountDataX.push(key);
            let idx = 0;
            for (let key2 in amountData[key]) {
              if (!amountDataY[idx]) amountDataY[idx] = []
              amountDataY[idx].push(amountData[key][key2] / 10000000);
              amountTotal += amountData[key][key2]
              idx += 1;
            }
          }
          this.amountDataX = amountDataX
          this.amountDataY = amountDataY
          this.amountTotal = Math.round(amountTotal);

        } catch (e) {
          console.log(e);
        } finally {
          console.log('finish');
        }
      }
    },
    mounted() {
      this.handleGetCustomer();
      this.handleGetProject();
      this.handleAmount()
    }
  }
</script>

<style lang='scss' scoped>
  .page-container {
    background-image: url("../../assets/imgs/info-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    .charts-container{
      padding-top: 35px;
      width: calc(1920px * 0.95);
      margin-left: calc(1920px * 0.025);
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .chart-title{
        box-sizing: border-box;
        width: 100%;
        height: 44px;
        border: 1px solid #257fbe;
        box-shadow: 0 0 26px 5px rgba(#257fbe, 0.4) inset;
        padding: 0 15px;
        line-height: 42px;
        display: flex;
        justify-content: space-between;
        .area-name{
          font-size: 20px;
          color: #65c6e7;
        }
        .area-count{
          font-size: 18px;
          color: #65c6e7;
          span{
            color: #3edb07;
          }
        }
      }
      .area-content{
        width: 100%;
        height: calc(1080px - 44px - 35px - 475.2px - 90px - 66px - 35px);
        .pie3d{
          position: relative;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
        .project-list{
          width: 100%;
          height: calc(100% - 15px);
          margin-top: 15px;
          .cell-1{
            box-sizing: border-box;
            float: left;
            padding-left: 10px;
            width: calc(100% - 110px - 2px);
            margin-right: 2px;
            background: rgba(#05192b, 0.5);
          }
          .cell-2{
            text-align: center;
            float: left;
            width: 110px;
            background: rgba(#05192b, 0.5);
          }
          .table-header{
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 18px;
            color: #fff;
            font-weight: bold;
            .table-cell{
              background: #05192b;
              box-sizing: border-box;
              border-top: 1px solid #163a57;
            }
          }
          .table-body{
            width: 100%;
            overflow: hidden;
            height: calc(100% - 28px);
          }
          .table-row{
            box-sizing: border-box;
            width: 100%;
            border-left: 1px solid #25577e;
            overflow: hidden;
            font-size: 18px;
            color: #a4bdd0;
            height: 30px;
            line-height: 30px;
            margin-bottom: 2px;
          }
        }
      }
      .chart-item{
        width: calc(1920px * 0.21875);
        &:nth-child(2) {
          width: calc(1920px * 0.2057);
        }
        &:nth-child(3) {
          width: calc(1920px * 0.228);
        }
        &:nth-child(4) {
          width: calc(1920px * 0.228);
        }
      }
    }
  }
</style>
