<template>
  <div class="chart-container">
    <div class="legend">
      <div class="unit">金额（千万）</div>
      <div class="items">
        <div class="item">
          <div class="rect" style="background: #18dffd"></div>
          <span>2020</span>
        </div>
        <div class="item">
          <div class="rect" style="background: #1880fd"></div>
          <span>2021</span>
        </div>
        <div class="item">
          <div class="rect" style="background: #fdc218"></div>
          <span>2022</span>
        </div>
      </div>
    </div>
    <div class="chart" ref="chart"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  let chart = null;
  export default {
    name: "index",
    props: {
      xData: {
        type: Array,
        default() {
          return ['2020', '2021', '2022']
        }
      },
      yData: {
        type: Array,
        default() {
          return [
            [10, 15, 33, 33, 55],
            [34, 11, 21, 14, 32],
            [21, 14, 15, 11, 39],
          ]
        }
      },
    },
    data() {
      let max = 0;
      this.yData.forEach(item => {
        item.forEach(item2 => {
          if (item2 > max) max = item2
        })
      })
      return {
        option: {
          tooltip: {
            trigger: 'item',
            // padding: 0,
            // backgroundColor: "#122c3d",
            // borderWidth: 0,
            // formatter: function(param) {
            //   let resultTooltip =
            //     "<div style='width: 120px;height: 70px;border-radius: 8px; padding-top: 10px;'>" +
            //     "<div style='font-size: 14px; color: #FEEF23; padding-left:10px; line-height: 30px;'>" + param.name + "</div>" +
            //     "<div style='font-size: 14px; color: #FEEF23; padding-left:10px; line-height: 30px;'>" +  param.value + "</div>" +
            //     "</div>";
            //   return resultTooltip;
            // }
          },
          grid: {
            left: '30px',
            top: '20px',
            right: '20px',
            bottom: '50px',
          },
          dataZoom: [
            {
              type: 'inside',
            }
          ],
          xAxis: [
            {
              data: this.xData,
              axisLabel: {
                interval: 0,
                textStyle: {
                  color: '#fff',
                  fontSize: 16
                },
                formatter:function(item){
                  let res = ''
                  if (item.indexOf('不安装') > -1) {
                    res = item.split('不安装')[0] + '\n' + '(不安装)'
                  } else if (item.indexOf('安装') > -1) {
                    res = item.split('安装')[0] + '\n' + '(安装)'
                  } else {
                    res = item;
                  }
                  return res
                }

              },
              axisLine: {
                show: true, //显示x轴
                lineStyle:{
                  color: "rgba(255,255,255,0.2)"
                }
              },
              axisTick: {
                show: true //显示刻度
              },
              boundaryGap: true,
              splitLine: {
                show: true,
                width: 0.08,
                lineStyle: {
                  type: "solid",
                  color: "rgba(255,255,255,0.2)"
                }
              },
            }
          ],
          yAxis: [
            {
              splitLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(255,255,255,0.2)',
                  type: 'solid'
                }
              },
              axisTick: {
                show: true,
              },
              axisLine: {
                show: true,
                lineStyle:{
                  color: "rgba(255,255,255,0.2)"
                }
              },
              axisLabel: {
                textStyle: {
                  color: '#fff',
                  fontSize: 16
                },
              }
            }
          ],
          series: [
            //柱顶圆片
            {
              name: "2020",
              type: "pictorialBar",
              symbolSize: [15, 10],
              symbolOffset: [-18, -5],
              z: 12,
              data: this.yData[0].map(item => +item + max*0.01),
              symbolPosition: "end", "itemStyle": {
                "normal": {
                  color: 'rgba(24,223,253,1)'
                }
              },
            },
            //柱底圆片
            {
              name: "2020",
              type: "pictorialBar",
              symbolSize: [15, 10],
              symbolOffset: [-18, 0],
              z: 12,
              data: this.yData[0],
              itemStyle: {
                "normal": {
                  color: "rgba(24,223,252,0.15)"
                }
              },
            },
            //柱顶圆片2
            {
              name: "2021",
              type: "pictorialBar",
              symbolSize: [15, 10],
              symbolOffset: [0, -5],
              z: 12,
              data: this.yData[1].map(item => +item + max*0.01),
              symbolPosition: "end", "itemStyle": {
                "normal": {
                  color: 'rgba(24,128,253,1)'
                }
              },
            },
            //柱底圆片2
            {
              name: "2021",
              type: "pictorialBar",
              symbolSize: [15, 10],
              symbolOffset: [0, 0],
              z: 12,
              data: this.yData[1],
              itemStyle: {
                "normal": {
                  color: "rgba(24,128,252,0.1)"
                }
              },
            },
            //柱顶圆片3
            {
              name: "2022",
              type: "pictorialBar",
              symbolSize: [15, 10],
              symbolOffset: [18, -5],
              z: 12,
              data: this.yData[2].map(item => +item + max*0.01),
              symbolPosition: "end", "itemStyle": {
                "normal": {
                  color: 'rgba(253,194,24,1)'
                }
              },
            },
            //柱底圆片3
            {
              name: "2022",
              type: "pictorialBar",
              symbolSize: [15, 10],
              symbolOffset: [18, 0],
              z: 12,
              data: this.yData[2],
              itemStyle: {
                "normal": {
                  color: "rgba(253,194,24,0.15)"
                }
              },
            },
            //柱体
            {
              name: '2020',
              type: 'bar',
              barWidth: 15,
              barGap: '20%',
              data: this.yData[0],
              itemStyle: {
                "normal": {
                  "color": {
                    "x": 0,
                    "y": 0,
                    "x2": 0,
                    "y2": 1,
                    "type": "linear",
                    "global": false,
                    "colorStops": [
                      {
                        "offset": 0,
                        "color": "rgba(3,172,241,1)"
                      },
                      {
                        "offset": 1,
                        "color": "rgba(3,172,241,0.1)"
                      }
                    ]
                  }
                }
              },
            },
            //柱体
            {
              name: '2021',
              type: 'bar',
              barWidth: 15,
              barGap: '20%',
              data: this.yData[1],
              itemStyle: {
                "normal": {
                  "color": {
                    "x": 0,
                    "y": 0,
                    "x2": 0,
                    "y2": 1,
                    "type": "linear",
                    "global": false,
                    "colorStops": [
                      {
                        "offset": 0,
                        "color": "rgba(0,68,244,1)"
                      },
                      {
                        "offset": 1,
                        "color": "rgba(24,128,253,0.1)"
                      }
                    ]
                  }
                }
              },
            },
            //柱体
            {
              name: '2022',
              type: 'bar',
              barWidth: 15,
              barGap: '20%',
              data: this.yData[2],
              itemStyle: {
                "normal": {
                  "color": {
                    "x": 0,
                    "y": 0,
                    "x2": 0,
                    "y2": 1,
                    "type": "linear",
                    "global": false,
                    "colorStops": [
                      {
                        "offset": 0,
                        "color": "rgba(239,219,5,1)"
                      },
                      {
                        "offset": 1,
                        "color": "rgba(239,219,5,0.1)"
                      }
                    ]
                  }
                }
              },
            },
          ]
        }
      }
    },
    methods: {
      initChart() {
        chart = echarts.init(this.$refs.chart)
        chart.setOption(this.option)
      },
      changeSize () {
        chart.resize()
      }
    },
    mounted () {
      this.initChart()
      //根据窗口变化自动调节图表大小
      const that = this
      window.onresize = function () {
        that.changeSize()
      }
    },
  }
</script>

<style scoped lang="scss">
  .chart-container{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 20;
    .legend{
      width: 100%;
      height: 40px;
      overflow: hidden;
      .unit{
        float: left;
        font-size: 15px;
        color: #eff7ff;
        padding-top: 22px;
      }
      .items{
        float: right;
        padding-top: 22px;
        .item{
          height: 15px;
          float: left;
          margin-right: 22px;
          display: flex;
          align-items: center;
          &:last-child{
            margin-right: 0;
          }
          span{
            font-size: 15px;
            color: #eff7ff;
            margin-left: 5px;
          }
          .rect{
            width: 14px;
            height: 4px;
            display: inline-block;
          }

        }
      }
    }
    .chart{
      width: 100%;
      height: calc(100% - 40px);
    }
  }
</style>
