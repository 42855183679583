import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from "@/store";
import { Message, Input } from 'element-ui';

Vue.prototype.$message = Message;
Vue.use(Input);


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('token')
  if (to.name !== 'login' && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
