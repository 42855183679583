<template>
  <div class="page-container">
    <img class="shadow" src="../../assets/imgs/case/shadow.png" alt="">
    <img class="light" src="../../assets/imgs/case/light.png" alt="">
    <div class="list">
      <div v-for="(item, idx) in 9" :key="idx">
        <img @click="handleGo(idx)" :src="require('../../assets/imgs/case/video'+ (idx + 1) +'.png')" alt="">
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Case',
    methods: {
      handleGo(idx) {
        this.$router.push({path: '/mediaInfo?idx=' + idx})
      }
    }
  }
</script>

<style lang='scss' scoped>
  .page-container {
    background-image: url("../../assets/imgs/case/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    .list{
      position: absolute;
      top: 190px;
      width: 1770px;
      left: calc((1920px - 1770px) / 2);
      display: flex;
      justify-content: space-between;
      div{
        cursor: pointer;
        height: 430px;
        display: flex;
        justify-content: flex-end;
        img {
          align-self: flex-end;
          transition: 0.3s ease;
          &:hover{
            transform: translateY(-10px);
          }
        }
      }
    }
    .light{
      position: absolute;
      left: calc((1920px - 1881px) / 2);
      bottom: 220px;
      z-index: 10;
      pointer-events: none;
      animation: fade 3s ease infinite;
    }
    @keyframes fade {
      0% {
        opacity: 1;
      }
      50%{
        opacity: 0;
      }
      100%{
        opacity: 1;
      }
    }
    .shadow{
      position: absolute;
      left: calc((1920px - 1786px) / 2);
      bottom: 0px
    }
  }
</style>
