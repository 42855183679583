<template>
  <div class="map-container">
    <div class="corner lt"></div>
    <div class="corner rt"></div>
    <div class="corner lb"></div>
    <div class="corner rb"></div>
    <div class="shape"></div>
    <div class="china-container">
      <China :current="current" :position.sync="position" type="info"></China>
      <AreaModal :position="position"></AreaModal>
    </div>
  </div>
</template>

<script>
  import China from '@/components/china'
  import AreaModal from '@/components/areaModal'
  export default {
    name: "index",
    components: {
      China,
      AreaModal
    },
    data() {
      return {
        position: 'left',
        current: localStorage.getItem('current') || '江苏',
      }
    }
  }
</script>

<style scoped lang="scss">
  .map-container{
    width: calc(1920px * 0.95);
    margin-left: calc(1920px * 0.025);
    height: calc(1080px * 0.44);;
    box-sizing: border-box;
    border: 1px solid #31467d;
    margin-top: 32px;
    position: relative;
    background: #000;
    .china-container{
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    }
    .shape{
      width: calc(100% + 32px);
      height: calc(100% + 32px);
      position: absolute;
      top: -16px;
      left: -16px;
      pointer-events: none;
      background-image: url("../../assets/imgs/map-shape.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .corner{
      width: 24px;
      height: 24px;
      border: 3px solid #65c6e7;
      box-sizing: border-box;
      position: absolute;
      pointer-events: none;
      z-index: 20;
    }
    .lt{
      border-right: none;
      border-bottom: none;
      top: -2px;
      left: -2px;
    }
    .rt{
      border-left: none;
      border-bottom: none;
      top: -2px;
      right: -2px;
    }
    .lb{
      border-right: none;
      border-top: none;
      bottom: -2px;
      left: -2px;
    }
    .rb{
      border-top: none;
      border-left: none;
      bottom: -2px;
      right: -2px;
    }

  }
</style>
