import http from '@/axios/http';

export function getHeaderInfo(params){
  return http.post('/china/info', params)
}

export function getDayInfo(params){
  return http.post('/day/info', params)
}

export function getRank(){
  return http.post('/amount/getRank')
}

