<template>
  <div class="page-container">
    <video v-if="idx >= 0 && idx <= 8" :src="listFrame[idx]" controls></video>
  </div>
</template>
<script>
  export default {
    name: 'MediaInfo',
    data() {
      return {
        list: [
          'https://www.bilibili.com/video/BV15m4y1m7iJ/',
          'https://www.bilibili.com/video/BV1Gc411p7Nz/',
          'https://www.bilibili.com/video/BV1RM411N7jF/',
          'https://www.bilibili.com/video/BV1ph411M7AT/',
          'https://www.bilibili.com/video/BV1do4y1n7Gr/',
          'https://www.bilibili.com/video/BV1Wv4y1H7zh/',
          'https://www.bilibili.com/video/BV1DM4y117TH/',
          'https://www.bilibili.com/video/BV1QM411N7MF/',
          'https://www.bilibili.com/video/BV1Tg4y1u7DA/',
        ],
        listFrame: [
          'http://video.jxuemoxingzhanshi.com/%E4%B8%AD%E4%BA%A4%E5%8D%96%E8%B4%A7%E9%83%8E.mp4', // 中交卖货郎
          'http://video.jxuemoxingzhanshi.com/%E8%A1%80%E6%B5%86%E9%80%9F%E5%BA%93.mp4', //血浆
          'http://video.jxuemoxingzhanshi.com/%E5%9B%B4%E6%8A%A4%E7%B3%BB%E7%BB%9F.mp4', //围护系统
          'http://video.jxuemoxingzhanshi.com/%E9%A6%96%E5%8F%91%E9%9B%86%E5%9B%A2.mp4', //首发集团
          'http://video.jxuemoxingzhanshi.com/%E7%AB%8B%E4%BD%93%E5%BA%93.mp4', //立体库
          'http://video.jxuemoxingzhanshi.com/%E5%98%89%E6%B5%A9%E7%89%A9%E6%B5%81.MP4', //嘉浩物流
          'http://video.jxuemoxingzhanshi.com/%E5%A4%B9%E8%8A%AF%E6%9D%BF%E5%AE%89%E8%A3%85.mp4.mp4', //夹芯板安装
          'http://video.jxuemoxingzhanshi.com/%E6%A0%B8%E9%85%B8%E9%87%87%E6%A0%B7%E7%AB%99.mp4', //核酸采样站
          'http://video.jxuemoxingzhanshi.com/%E7%94%B5%E5%8A%A8%E5%B9%B3%E7%A7%BB%E9%97%A8.mp4', //电动平移门
        ],
        idx: -1
      }
    },
    mounted() {
      this.idx = this.$route.query.idx;
    }
  }
</script>
<style>


  video::-webkit-media-controls {
    position: relative;
    bottom: 66px;
  }

</style>
<style lang='scss' scoped>
  .page-container {
    background-color: #000;
    background-size: cover;
    background-repeat: no-repeat;

  }
</style>
