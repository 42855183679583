<template>
    <div class="china" ref="china" :class="{forbidden: type == 'info', loaded: loaded}">
        <div class="bg" v-if="type == 'info'">
            <img src="../../assets/imgs/bg.jpg" alt="">
        </div>
        <img class="china-bg" src="../../assets/imgs/china.png" alt="">
        <img v-if="type == 'index'" class="china-name" src="../../assets/imgs/name.png" alt="">
<!--        <img v-for="item in list"-->
<!--             @click="setCurArea(item.name)"-->
<!--             :key='item.name'-->
<!--             :ref="item.name"-->
<!--             :class="current == item.name ? 'area area-active' : 'area'"-->
<!--             :style="{left: item.left + 'px', top: item.top + 'px', zIndex: item.zIndex ? item.zIndex : 5}"-->
<!--             :src="require(`../../assets/imgs/area/${item.name}.png`)"-->
<!--             :alt="item.name">-->
        <div v-for="item in list"
             @click="setCurArea(item.name)"
             :key='item.name'
             :ref="item.name"
             :class="current == item.name ? 'area area-active' : 'area'"
             :style="{left: item.left + 'px', top: item.top + 'px', zIndex: item.zIndex ? item.zIndex : 5}"
        >
            <img :src="require(`../../assets/imgs/area/${item.name}.png`)" :alt="item.name" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        props: {
            type: {
                type: String,
                default: 'index'
            },
            current: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                list: [
                    {name: '台湾', left: 753, top: 457},
                    {name: '江苏', left: 694, top: 298},
                    {name: '上海', left: 767, top: 346},
                    {name: '安徽', left: 672, top: 315},
                    {name: '浙江', left: 720, top: 369},
                    {name: '天津', left: 699, top: 212, zIndex: 10},
                    {name: '辽宁', left: 734, top: 160, zIndex: 10},
                    {name: '北京', left: 679, top: 199, zIndex: 10},
                    {name: '河北', left: 648, top: 177, zIndex: 10},
                    {name: '山西', left: 596, top: 208, zIndex: 10},
                    {name: '陕西', left: 521, top: 228},
                    {name: '宁夏', left: 502, top: 229, zIndex: 10},
                    {name: '内蒙古', left: 394, top: 7},
                    {name: '吉林', left: 782, top: 115},
                    {name: '黑龙江', left: 777, top: -3},
                    {name: '山东', left: 671, top: 247},
                    {name: '河南', left: 599, top: 278},
                    {name: '湖北', left: 568, top: 327},
                    {name: '重庆', left: 519, top: 346},
                    {name: '贵州', left: 492, top: 394, zIndex: 10},
                    {name: '湖南', left: 573, top: 381},
                    {name: '江西', left: 648, top: 382},
                    {name: '福建', left: 686, top: 409},
                    {name: '海南', left: 570, top: 537},
                    {name: '广东', left: 578, top: 452},
                    {name: '广西', left: 506, top: 439},
                    {name: '云南', left: 395, top: 394, zIndex: 10},
                    {name: '四川', left: 392, top: 314},
                    {name: '甘肃', left: 315, top: 174},
                    {name: '青海', left: 268, top: 232},
                    {name: '西藏', left: 92, top: 276},
                    {name: '新疆', left: 15, top: 72},
                ],
                position: 'left',
                loaded: false
            }
        },
        methods: {
            setCurArea(name) {
                if (name === this.current) return;
                localStorage.setItem('current', name)
                this.$emit('update:current', name)
            },
            translateChina() {

                const china = this.$refs['china'];
                const parElement = this.$refs[this.current][0];
                const element = parElement.getElementsByTagName('img')[0]

                element.addEventListener('load', () => {
                    var scale = 1.5

                    var parentRect = china.getBoundingClientRect();
                    var childRect = element.getBoundingClientRect();

                    var relativeLeft = childRect.left * scale - parentRect.left * scale;
                    var relativeTop = childRect.top * scale - parentRect.top * scale;

                    var offsetX = childRect.width * scale / 2;
                    var offsetY = childRect.height * scale / 2;

                    var centerX = parentRect.width * scale / 2;
                    var centerY = parentRect.height * scale / 2;

                    if (centerX - relativeLeft - offsetX > 0) {
                        this.$emit('update:position', 'left')
                    } else {
                        this.$emit('update:position', 'right')
                    }
                    this.loaded = true;
                    china.style.transform = `translate(${centerX - relativeLeft - offsetX}px, ${centerY- relativeTop - offsetY}px) scale(${scale})`
                })
            }
        },
        mounted() {
            if (this.type == 'info') {
                this.translateChina()
            }
        }
    }
</script>

<style scoped lang="scss">
    .bg{
        width: 1920px;
        height: 1080px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }
    .forbidden{
        pointer-events: none;
        transition: 0.3s;
        opacity: 0;
    }
    .loaded{
        opacity: 1;
    }
    .china{
        width: 1031px;
        height: 643px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 30;
        .china-bg{
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
        }
        .china-name{
            width: 651px;
            height: 474px;
            position: absolute;
            z-index: 20;
            left: 220px;
            top: 98px;
            pointer-events: none;
        }
        .area{
            position: absolute;
            transform: scale(0.5) translateY(20px);
            transform-origin: top left;
            opacity: 0;
            z-index: 5;
            transition: 0.3s;
            cursor: pointer;
        }
        .area-active{
            opacity: 1;
            z-index: 3!important;
            transform: scale(0.5) translateY(0px);
            animation: area-ani 4s ease infinite;
            img{
                position: relative;
                z-index: 10;
            }
            &:before{
                content: '';
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                background: rgba(203, 202, 202, 0.2);
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                animation: fade-ani 3s ease infinite;
                pointer-events: none;

            }
            &:after{
                content: '';
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                background: rgba(200,200,200,0.2);
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                animation: fade-ani2 3s ease 0.5s infinite;
                pointer-events: none;
            }
        }
        @keyframes fade-ani {
            0%{
                transform: scale(1);
                background: rgba(200,200,200,0.2);
            }
            100%{
                transform: scale(20);
                background: rgba(200,200,200,0);
            }

        }
        @keyframes fade-ani2 {
            0%{
                transform: scale(1);
                background: rgba(200,200,200,0.2);
            }
            100%{
                transform: scale(15);
                background: rgba(200,200,200,0);
            }

        }
        @keyframes area-ani {
            0%{
                transform: scale(0.5) translateY(0);
            }
            50%{
                transform: scale(0.5) translateY(-10px);
            }
            100%{
                transform: scale(0.5) translateY(0);
            }

        }
    }
</style>
