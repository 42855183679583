<template>
  <div class="chart-container">
    <div class="chart" ref="chart"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  let chart = null;
  export default {
    name: "index",
    props: {
      xData: {
        type: Array,
        default() {
          return []
        }
      },
      yData: {
        type: Array,
        default() {
          return []
        }
      },
    },
    data() {
      return {
        option: {
          color: ['rgb(0,228,218)'],
          tooltip: {
            trigger: 'item',
          },
          graphic: [
            {
              type: 'text',
              left: '0px',
              bottom: '13px',
              style: {
                fill: '#eff7ff',
                text: '年份',
                fontSize: '15px'
              }
            },
            {
              type: 'text',
              left: '0px',
              top: '13px',
              style: {
                fill: '#eff7ff',
                text: '单位：万元',
                fontSize: '15px'
              }
            }
          ],
          legend: {
            show: false,
            align: 'left',
            right: '0%',
            top: '5%',
            textStyle: {
              fontSize: '15px',
              color: '#eff7ff'
            },
            icon: 'rect',
            itemWidth: 14,
            itemHeight: 4,
            itemGap: 22
          },
          grid: {
            left: '60px',
            top: '50px',
            right: '20px',
            bottom: '35px',
          },
          dataZoom: [
            {
              type: 'inside',
            }
          ],
          xAxis: [
            {
              data: this.xData,
              axisLabel: {
                textStyle: {
                  color: '#fff',
                  fontSize: 16
                },
              },
              axisLine: {
                show: false, //显示x轴
                lineStyle:{
                  color: "rgba(255,255,255,0.2)"
                }
              },
              axisTick: {
                show: false //显示刻度
              },
              boundaryGap: false,
              splitLine: {
                show: false,
                width: 0.08,
                lineStyle: {
                  type: "solid",
                  color: "rgba(255,255,255,0.2)"
                }
              },
            }
          ],
          yAxis: [
            {
              splitLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(255,255,255,0.2)',
                  type: 'solid'
                }
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
                lineStyle:{
                  color: "rgba(255,255,255,0.2)"
                }
              },
              axisLabel: {
                textStyle: {
                  color: '#fff',
                  fontSize: 16
                },
              }
            }
          ],
          series: [
            {
              name: 'Line 1',
              type: 'line',
              stack: 'Total',
              lineStyle: {
                width: 2,
                color: 'rgba(0,228,218,1)'
              },
              showSymbol: true,
              smooth: true,
              itemStyle: {
                color: 'rgba(47,70,191,1)'
              },
              areaStyle: {
                opacity: 1,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(0,228,218,0.7)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(0,228,218,0)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: this.yData
            },
          ]
        }
      }
    },
    methods: {
      initChart() {
        chart = echarts.init(this.$refs.chart)
        chart.setOption(this.option)
      },
      changeSize () {
        chart.resize()
      }
    },
    mounted () {
      this.initChart()
      //根据窗口变化自动调节图表大小
      const that = this
      window.onresize = function () {
        that.changeSize()
      }
    },
  }
</script>

<style scoped lang="scss">
  .chart-container{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 20;
    .chart{
      width: 100%;
      height: 100%;
    }
  }
</style>
