import http from '@/axios/http';

export function getCustomer(params){
    return http.post('/customer/list', params)
}

export function getAmount(params){
    return http.post('/amount/list', params)
}

export function getProject(params){
    return http.post('/project/list', params)
}
