<template>
  <div class="chart-container" :style="{width: width + 'px', height: height + 'px',}">
    <div class="chart" id="container" ref="container"></div>
    <img v-if="type === 'normal'" class="pie-bg" src="./pie-bg.png" alt="">
    <img v-if="type === 'small'" class="pie-bg-small" src="./pie-bg-small.png" alt="">
  </div>
</template>

<script>

  import Highcharts from 'highcharts';
  import Highcharts3D from 'highcharts/highcharts-3d';
  Highcharts3D(Highcharts);

  export default {
    props: {
      type: {
        type: String,
        default: 'normal'
      },
      width: {
        type: Number,
        default: 600
      },
      height: {
        type: Number,
        default: 280
      },
      size: {
        type: Number,
        default: 100
      },
      unit: {
        type: String,
        default: ''
      },
      color: {
        type: Array,
        default() {
          return  [
            ['#19596d', '#2ea1b2'],
            ['#ee7529', '#f5a86c'],
            ['#f5c055', '#967b3d'],
            ['#aadcf6', '#5694b1'],
            ['#2d7bb5', '#1a5784'],
          ]
        }
      },
      data: {
        type: Array,
        default() {
          return [
            { name: '食', y: 28, h: 60 },
            { name: '食饮2', y: 20, h: 20 },
            { name: '食料餐饮3', y: 10, h: 32 },
            { name: '食餐饮4', y: 6, h: 45 },
            { name: '食品饮料餐s饮5', y: 10, h: 32 }
          ]
        }
      }
    },

    computed: {},
    updated () {},
    created () {},
    mounted () {
      this.initChart()
      const that = this
      window.onresize = function () { that.initChart() }
    },
    methods: {
      initChart () {
        let unit = this.unit
        // 生成颜色表
        const genColor = () => {
          let res = [];
          this.color.forEach(item => {
            res.push( { // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
              linearGradient: {
                x1: 0,
                y1: 1,
                x2: 1,
                y2: 0
              },
              stops: [
                [0, item[0]],
                [1, item[1]]
              ]
            })
          })
          return res;
        }

        // 修改3d饼图绘制过程
        const each = Highcharts.each
        const round = Math.round
        const cos = Math.cos
        const sin = Math.sin
        const deg2rad = Math.PI / 180
        Highcharts.wrap(Highcharts.seriesTypes.pie.prototype, 'translate', function (proceed) {
          proceed.apply(this, [].slice.call(arguments, 1))
          // Do not do this if the chart is not 3D
          if (!this.chart.is3d()) {
            return
          }
          const series = this
          const chart = series.chart
          const options = chart.options
          const seriesOptions = series.options
          const depth = seriesOptions.depth || 0
          const options3d = options.chart.options3d
          const alpha = options3d.alpha
          const beta = options3d.beta
          var z = seriesOptions.stacking ? (seriesOptions.stack || 0) * depth : series._i * depth
          z += depth / 2
          if (seriesOptions.grouping !== false) {
            z = 0
          }
          each(series.data, function (point) {
            const shapeArgs = point.shapeArgs
            var angle
            point.shapeType = 'arc3d'
            var ran = point.options.h
            shapeArgs.z = z
            shapeArgs.depth = depth * 0.75 + ran
            shapeArgs.alpha = alpha
            shapeArgs.beta = beta
            shapeArgs.center = series.center
            shapeArgs.ran = ran
            angle = (shapeArgs.end + shapeArgs.start) / 2
            point.slicedTranslation = {
              translateX: round(cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)),
              translateY: round(sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad))
            }
          })
        });
        (function (H) {
          H.wrap(Highcharts.SVGRenderer.prototype, 'arc3dPath', function (proceed) {
            // Run original proceed method
            const ret = proceed.apply(this, [].slice.call(arguments, 1))
            ret.zTop = (ret.zOut + 0.5) / 100
            return ret
          })
        }(Highcharts))
        // 生成不同高度的3d饼图
        const highEcharts = this.$refs.container
        Highcharts.chart(highEcharts, {
          chart: {
            type: 'pie',
            animation: false,
            backgroundColor: 'rgba(0,0,0,0)',
            events: {
              load: function () {
                const each = Highcharts.each
                const points = this.series[0].points
                each(points, function (p) {
                  p.graphic.attr({
                    translateY: -p.shapeArgs.ran
                  })
                  p.graphic.side1.attr({
                    translateY: -p.shapeArgs.ran
                  })
                  p.graphic.side2.attr({
                    translateY: -p.shapeArgs.ran
                  })
                })
              },
              resize: function () {
                const each = Highcharts.each
                const points = this.series[0].points
                each(points, function (p) {
                  p.graphic.attr({
                    translateY: -p.shapeArgs.ran
                  })
                  p.graphic.side1.attr({
                    translateY: -p.shapeArgs.ran
                  })
                  p.graphic.side2.attr({
                    translateY: -p.shapeArgs.ran
                  })
                })
              }
            },
            options3d: {
              enabled: true,
              alpha: 65
            }
          },
          title: {
            show: 'false',
            text: null
          },
          subtitle: {
            text: null
          },
          credits: {
            enabled: false
          },
          // legend: { // 【图例】位置样式
          //   backgroundColor: 'rgba(0,0,0,0)',
          //   shadow: false,
          //   layout: 'vertical',
          //   align: 'right', // 水平方向位置
          //   verticalAlign: 'top', // 垂直方向位置
          //   x: 0, // 距离x轴的距离
          //   y: 100, // 距离Y轴的距离
          //   symbolPadding: 10,
          //   symbolHeight: 14,
          //   itemStyle: {
          //     lineHeight: '24px',
          //     fontSize: '16px',
          //     color: '#fff'
          //   },
          //   labelFormatter: function () {
          //     /*
          //     *  格式化函数可用的变量：this， 可以用 console.log(this) 来查看包含的详细信息
          //     *  this 代表当前数据列对象，所以默认的实现是 return this.name
          //     */
          //     return this.name + this.h + '%'
          //   }
          // },
          plotOptions: {
            pie: {
              allowPointSelect: false,
              cursor: 'pointer',
              depth: 35,
              size: this.size + '%',
              innerSize: '60%',
              opacity: 1,
              dataLabels: {
                connectorColor: '#fff',
                enabled: true,
                formatter: function() {
                  return `<div style="color: #41a6fc;font-size: 21px;display: block">${this.point.name}</div><br><div style="color: #fff;font-size: 21px;display: block">${this.y}${unit}</div>`
                }
              },
              // 显示图例
              showInLegend: false,
            }
          },
          series: [{
            type: 'pie',
            name: '数量',
            // h 是高度  y是占的圆环长度
            colorByPoint: true,
            colors: genColor(),
            data: this.data
          }]
        })
      }
    },
    components: {}
  }
</script>

<style scoped lang="scss">
  .chart-container{
    width: 600px;
    height: 280px;
    position: relative;
    .chart{
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 10;
    }
    .pie-bg{
      display: block;
      width: 347px;
      height: 148px;
      position: absolute;
      z-index: 5;
      left: 50%;
      transform: translateX(-50%);
      top: 40%;
    }
    .pie-bg-small{
      display: block;
      width: 213px;
      height: 94px;
      position: absolute;
      z-index: 5;
      left: 50%;
      transform: translateX(-50%);
      top: 45%;
    }
  }
</style>
