<template>
  <div class="chart-container">
    <div class="chart" ref="chart"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  let chart = null;
  export default {
    name: "index",
    props: {
      xData: {
        type: Array,
        default() {
          return ['冷库门', '工业门', '平台']
        }
      },
      yData: {
        type: Array,
        default() {
          return [
            [10, 15, 33, 33, 55],
            [34, 11, 21, 14, 32],
            [21, 14, 15, 11, 39],
          ]
        }
      },
    },
    data() {
      return {
        option: {
          // graphic: {
          //   type: 'text',
          //   left: '0%',
          //   bottom: '15px',
          //   style: {
          //     fill: '#eff7ff',
          //     text: '年份',
          //     fontSize: '12px'
          //   }
          // },
          legend: {
            align: 'left',
            right: '0%',
            top: '5%',
            textStyle: {
              fontSize: '15px',
              color: '#eff7ff'
            },
            icon: 'rect',
            itemWidth: 14,
            itemHeight: 8,
            itemGap: 22
          },
          tooltip: {
            trigger: 'item',
          },
          grid: {
            left: '70px',
            top: '20px',
            right: '30px',
            bottom: '35px',
          },
          dataZoom: [
            {
              type: 'inside',
            }
          ],
          xAxis: [
            {
              type: 'value',
              splitNumber: 3,
              axisLabel: {
                textStyle: {
                  color: '#fff',
                  fontSize: 14
                },
              },
              axisLine: {
                show: false, //显示x轴
                lineStyle:{
                  color: "rgba(255,255,255,0.2)"
                }
              },
              axisTick: {
                show: false //显示刻度
              },
              boundaryGap: true,
              splitLine: {
                show: false,
                width: 0.08,
                lineStyle: {
                  type: "solid",
                  color: "rgba(255,255,255,0.2)"
                }
              },
            }
          ],
          yAxis: [
            {
              type: 'category',
              data: this.xData,
              splitLine: {
                show: false,
                lineStyle: {
                  color: 'rgba(255,255,255,0.2)',
                  type: 'solid'
                }
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
                lineStyle:{
                  color: "rgba(255,255,255,0.2)"
                }
              },
              axisLabel: {
                textStyle: {
                  color: '#fff',
                  fontSize: '16px'
                },
              }
            }
          ],
          series: []
        }
      }
    },
    methods: {
      genSeries() {
        let names = ['2020', '2021', '2022']
        let colors = [
          ['rgba(22,198,196,1)', 'rgba(22,198,196,0)'],
          ['rgba(0,172,251,1)', 'rgba(0,172,251,0)'],
          ['rgba(251,154,93,1)', 'rgba(251,154,93,0)'],
        ]
        let series = [];
        names.forEach((item, index) => {
          series.push(
            {
              name: item,
              type: 'bar',
              data: this.yData[index],
              barWidth: 10,
              barGap: '30%%',
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(217,239,240, 0.1)'
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: colors[index][1] },
                  { offset: 1, color: colors[index][0] }
                ]),
                shadowColor: colors[index][0],
                shadowBlur: 10
              },
            },
          )
        })
        this.option.series = series
      },
      initChart() {
        this.genSeries();
        chart = echarts.init(this.$refs.chart)
        chart.setOption(this.option)
      },
      changeSize () {
        chart.resize()
      }
    },
    mounted () {
      this.initChart()
      //根据窗口变化自动调节图表大小
      const that = this
      window.onresize = function () {
        that.changeSize()
      }
    },
  }
</script>

<style scoped lang="scss">
  .chart-container{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 20;
    .chart{
      width: 100%;
      height: 100%;
    }
  }
</style>
