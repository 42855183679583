<template>
    <div class="page">
        <div class="bg">
            <img src="../../assets/imgs/bg.jpg" alt="">
        </div>
        <div class="page-container">
            <div class="header">
                <img src="../../assets/imgs/logo.png" alt="">
                <div class="btns">
                    <div class="btn">登录</div>
<!--                    <div class="btn register">注册</div>-->
                </div>
            </div>
            <div class="tips-container">
                <div class="tip1">WELCOME TO SCRM SYSTEM</div>
                <div class="tip2">企业数据列表分析 可视化数据查询系统</div>
                <div class="tip3">科技让数据触手可及</div>
            </div>
            <div class="form-container">
                <div class="input-item">
                    <el-input v-model="username" placeholder="请输入您的登录账号"></el-input>
                </div>
                <div class="input-item" style="margin-bottom: 45px">
                    <el-input type="password" v-model="password" placeholder="请输入您的登录密码"></el-input>
                </div>
                <div class="submit" @click="handleLogin()">登 录</div>
            </div>
            <div class="copyright">CopyRight © 2023 晶雪节能科技股份有限公司版权所有
                <notempty name="config.icp_num"><a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2023006589号-1</a></notempty> |
                <notempty name="config.icp_num"><a href="https://www.beian.gov.cn/" target="_blank">苏公网安备32041202001986号</a></notempty>
            </div>
        </div>
    </div>
</template>

<script>
    import {login} from '@/api/user'
    export default {
        name: "Login",
        data() {
            return {
                username: '',
                password: '',
            }
        },
        methods: {
            async handleLogin() {
                if (!this.password || !this.username) {
                    this.$message({
                        message: '请输入账号密码',
                        type: 'error',
                    });
                    return;
                }
                try{
                    const {
                        data: {
                            data: {
                                token: token
                            }
                        }
                    } = await login({
                        username: this.username,
                        password: this.password
                    });
                    if (token) {
                        localStorage.setItem('token', token)
                        this.$router.push('/index')
                    } else {
                        this.$message({
                            message: '账号或密码错误',
                            type: 'error',
                        });
                    }
                } catch (e) {
                    console.log(e);
                } finally {
                    console.log('finish');
                }
            }
        },
        mounted() {

        }
    }
</script>

<style scoped lang="scss">
    .page{
        width: 1920px;
        height: 1080px;
        box-sizing: border-box;
        padding-left: 133px;
        padding-right: 133px;
        position: relative;
        .page-container{
            position: relative;
            z-index: 10;
        }
        .bg{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            width: 100%;
            height: 100%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
        }
        .header{
            height: 66px;
            width: calc(1920px - 266px);
            position: fixed;
            top: 32px;
            z-index: 10;
            .logo{
                float: left;
            }
            .btns{
                float: right;
                width: 244px;
                display: flex;
                justify-content: space-between;
                padding-top: 23px;
                .btn{
                    width: 113px;
                    height: 41px;
                    border: 1px solid #fff;
                    text-align: center;
                    line-height: 41px;
                    font-size: 15px;
                    color: #fff;
                    cursor: pointer;
                }
                .register{
                    color: #170b58;
                    background: #fff;
                }
            }
        }
        .copyright{
            width: 100%;
            text-align: center;
            height: 46px;
            position: fixed;
            bottom: 0;
            left: 0;
            font-size: 21px;
            color: rgba(#fff, 0.75);
            a{
                text-decoration: none;
                color: #fff;
            }
        }
        .tips-container{
            width: 100%;
            padding-top: calc(1080px * 0.21);
            .tip1{
                font-size: 29px;
                color: #fff;
                margin-bottom: 30px;
                letter-spacing: 3px;
            }
            .tip2{
                font-size: 43px;
                color: #fff;
                margin-bottom: 30px;
            }
            .tip3{
                font-size: 31px;
                color: #fff;
                letter-spacing: 5px;
            }
        }
        .form-container{
            margin: 0 auto;
            width: 420px;
            padding-top: calc(1080px * 0.1);
            .input-item{
                margin-bottom: 25px;
            }
            .submit{
                width: 186px;
                height: 59px;
                border-radius: 59px;
                background: #2d97b2;
                text-align: center;
                line-height: 59px;
                font-size: 25px;
                color: #fff;
                margin: 0 auto;
                cursor: pointer;
            }
            /deep/ {
                .el-input__inner{
                    background: none;
                    width: 420px;
                    height: 76px;
                    line-height: 76px;
                    border-radius: 76px;
                    border: 1px solid #fff;
                    text-align: center;
                    color: #fff;
                    font-size: 23px;
                }
                input::placeholder{
                    color: #a1a1a1;
                    font-size: 23px;
                    text-align: center;
                }
            }
        }
    }
</style>
