<template>
    <div class="page" :class="loaded ? 'loaded' : ''">
        <img class="title" src="../../assets/imgs/index-title.png" alt="">
        <img class="left" src="../../assets/imgs/index-left.png" alt="">
        <img class="right" src="../../assets/imgs/index-right.png" alt="">
        <div class="bg">
            <img src="../../assets/imgs/bg.jpg" alt="">
        </div>
        <div class="table">
            <div class="t-header">
                <div class="t-cell t-cell1"><span>省份销售排行</span></div>
                <div class="t-cell t-cell2"><span>省份</span></div>
                <div class="t-cell t-cell3"><span>合同数</span></div>
                <div class="t-cell t-cell4"><span>金额</span></div>
                <div class="t-cell t-cell5"><span>份额比</span></div>
            </div>
            <div class="t-row" v-for="(item, idx) in list">
                <div class="t-cell t-cell1">
                    <div class="circle" :class="'circle-' + (idx + 1)"></div>
                    <span v-if="idx == 0">第一</span>
                    <span v-if="idx == 1">第二</span>
                    <span v-if="idx == 2">第三</span>
                    <span v-if="idx == 3">第四</span>
                    <span v-if="idx == 4">第五</span>
                </div>
                <div class="t-cell t-cell2">{{item.area}}</div>
                <div class="t-cell t-cell3">{{Math.round(item.contract)}}</div>
                <div class="t-cell t-cell4">{{formatMoney(Number(item.totalValue))}}</div>
                <div class="t-cell t-cell5">{{Math.round(item.totalValue / total * 100)}}%</div>
            </div>
        </div>
        <China :current.sync="current"></China>
    </div>
</template>

<script>
    import China from '@/components/china'
    import {getRank} from '@/api/global'
    export default {
        name: "Index",
        components: {
            China
        },
        data() {
            return {
                current: localStorage.getItem('current') || '江苏',
                loaded: false,
                total: 0,
                list: []
            }
        },
        methods: {
            formatMoney(amount) {
                if (amount && typeof +amount == 'number') {
                    amount = +amount;
                    let res = new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }).format(amount);
                    return res;
                }
            },
        },
        mounted() {
            getRank().then(res => {
                this.total = res.data.data.total;
                this.list = res.data.data.data;
            })
            setTimeout(() => {
                this.loaded = true
            }, 100)
        }
    }
</script>

<style scoped lang="scss">
    .table{
        position: absolute;
        width: 360px;
        top: 680px;
        left: 220px;
        z-index: 20;
        .t-header{
            overflow: hidden;
            border-bottom: 2px solid #779ec3;
            width: 100%;
            height: 40px;
            line-height: 40px;
            .t-cell{
                text-align: center;
                span{
                    font-size: 16px;
                    color: #ffffff;
                    background-image:-webkit-linear-gradient(bottom,#548fab, #fff, #fff);
                    -webkit-background-clip:text;
                    -webkit-text-fill-color:transparent;
                }
            }
            .t-cell1{
                text-align: left;
            }
            .t-cell5{
                text-align: right;
            }
        }
        .t-row{
            overflow: hidden;
            width: 100%;
            background-color: rgba(#fff, 0.1);
            color: #fff;
            font-size: 16px;
            line-height: 34px;
            .t-cell{
                font-size: 16px;
                text-align: center;
                height: 34px;
                position: relative;
            }
            border-bottom: 2px solid rgba(#fff, 0.2);
            .circle{
                width: 8px;
                height: 8px;
                position: absolute;
                top: 13px;
                left: 9px;
                border-radius: 50%;
                @include linearGradient(103deg, #e15b56, #f2c760)
            }
            .circle-2{
                @include linearGradient(103deg, #02d0d1, #02d0d1);
            }
            .circle-3{
                @include linearGradient(103deg, #62c2f1, #62c2f1);
            }
            .circle-4{
                @include linearGradient(103deg, #3097f9, #3097f9);
            }
            .circle-5{
                @include linearGradient(103deg, #91c3ff, #91c3ff);
            }
        }
        .odd{
            background-color: rgba(#afd4f2, 0.2);
        }
        .t-cell{
            float: left;
        }
        .t-cell1{width: 100px;}
        .t-cell2{width: 50px;}
        .t-cell3{width: 60px;}
        .t-cell4{width: 100px;}
        .t-cell5{width: 50px;}
    }
    .page{
        width: 1920px;
        height: 1080px;
        position: relative;
        z-index: 20;
        .title{
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            opacity: 0;
            transform: translateY(-10px);
            transition: 0.8s ease;
            pointer-events: none;
        }
        .left{
            position: absolute;
            left: 70px;
            z-index: 99;
            height: calc(1080px * 0.8);
            top: calc(1080px * 0.1);
            pointer-events: none;
            opacity: 0;
            transition: 0.8s ease;
            transition-delay: 0.2s;
            transform: translateX(-30px);
        }
        .right{
            position: absolute;
            right: 70px;
            z-index: 99;
            height: calc(1080px * 0.8);
            top: calc(1080px * 0.1);
            pointer-events: none;
            opacity: 0;
            transition: 0.8s ease;
            transition-delay: 0.2s;
            transform: translateX(30px);
        }
        .bg{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            width: 100%;
            height: 100%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
        }
    }
    .loaded{
        .title{
            opacity: 1;
            transform: translateY(0px);
        }
        .left{
            opacity: 1;
            transform: translateX(0px);
        }
        .right{
            opacity: 1;
            transform: translateX(0px);
        }
    }
</style>
