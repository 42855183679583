import http from '@/axios/http';

export function getPunter(params){
    return http.post('/punter/list', params)
}

export function getOwner(params){
    return http.post('/owner/list', params)
}

export function getCountry(params){
    return http.post('/country/list', params)
}
