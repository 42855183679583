<template>
  <div class="page-container">
    <InfoHeader></InfoHeader>
    <InfoChina></InfoChina>
    <div class="charts-container">
      <div class="chart-item">
        <div class="chart-title">
          <div class="area-name">{{curArea}}-板材类数据</div>
          <div class="area-count">
            总金额<span>约{{ Math.round(total1) }}¥</span>
          </div>
        </div>
        <div class="area-content">
          <Line1
            v-if="showLine"
            :xData="xDataLine1"
            :yData="yDataLine1"
          ></Line1>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-title">
          <div class="area-name">{{curArea}}-门类数据</div>
          <div class="area-count">
            总金额 <span>约{{ Math.round(total2) }}¥</span>
          </div>
        </div>
        <div class="area-content">
          <Line2
            v-if="showLine"
            :xData="xDataLine2"
            :yData="yDataLine2"
          ></Line2>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-title">
          <div class="area-name">全国-产品分类销售数量</div>
          <div style="display: flex; justify-content: space-between">
            <div class="area-count" style="margin-right: 30px">
              板材总平米数 <span>约{{ Math.round(total3)}}㎡</span>
            </div>
            <div class="area-count">
              门总件数 <span>约{{ Math.round(total4) }}件</span>
            </div>
          </div>
        </div>
        <div class="area-content">
          <div class="bar-item">
            <Bar4 v-if="showBar" :xData="xDataLine3" :yData="yDataLine3"></Bar4>
          </div>
          <div class="bar-item">
            <Bar2 v-if="showBar" :xData="xDataLine4" :yData="yDataLine4"></Bar2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar2 from '@/components/bar2';
import Bar4 from '@/components/bar4';
import InfoHeader from '@/components/infoHeader';
import InfoChina from '@/components/infoChina';
import Line1 from '@/components/line1';
import Line2 from '@/components/line2';
import { getDoorList, getSalesList } from '@/api/category';
export default {
  name: 'chart',
  components: {
    InfoHeader,
    InfoChina,
    Bar2,
    Bar4,
    Line1,
    Line2,
  },
  data() {
    return {
      xDataLine1: [],
      yDataLine1: [],
      xDataLine2: [],
      yDataLine2: [],
      xDataLine3: [],
      yDataLine3: [],
      xDataLine4: [],
      yDataLine4: [],
      showLine: false,
      showBar: false,
      total1: 0,
      total2: 0,
      total3: 0,
      total4: 0,
      curArea: localStorage.getItem('current') || '江苏'
    };
  },
  methods: {
    async handleGetDoorList() {
      try {
        const response = await getDoorList({
          // area: localStorage.getItem('current') || '江苏',
          area: localStorage.getItem('current') || '江苏',
          pageSize: 9999,
        });

        // 将数据按照type分类
        const typeMap = response.data.data.data.reduce((map, item) => {
          if (!map[item.type]) {
            map[item.type] = [];
          }
          map[item.type].push(item);
          return map;
        }, {});

        // 将板和门的数据按照年份排序
        const sortedData = {
          板: typeMap['板'].sort((a, b) => parseInt(a.year) - parseInt(b.year)),
          门: typeMap['门'].sort((a, b) => parseInt(a.year) - parseInt(b.year)),
        };

        // 输出结果
        // console.log(
        //   '板的year数组和value数组：',
        //   sortedData['板'].map((item) => ({
        //     year: item.year,
        //     value: item.value,
        //   })),
        // );
        this.xDataLine1 = sortedData['板'].map((item) => item.year);
        this.yDataLine1 = sortedData['板'].map((item) => parseFloat(item.value / 10000));
        sortedData['板'].forEach(
          (item) => (this.total1 += parseFloat(item.value)),
        );
        // console.log(
        //   '门的year数组和value数组：',
        //   sortedData['门'].map((item) => ({
        //     year: item.year,
        //     value: item.value,
        //   })),
        // );
        this.xDataLine2 = sortedData['门'].map((item) => item.year);
        this.yDataLine2 = sortedData['门'].map((item) => parseFloat(item.value / 10000));
        sortedData['门'].forEach(
          (item) => (this.total2 += parseFloat(item.value)),
        );
        this.$nextTick(() => {
          this.showLine = true;
        });
      } catch (e) {
        console.log(e);
      } finally {
        console.log('finish');
      }
    },
    async handleGetSalesList() {
      try {
        const response = await getSalesList({ pageSize: 9999 });
        let list = response.data.data.data;
        list.forEach(item => {
          if (item.type === '聚氨酯板') {
            item.sort = 1
          } else if (item.type === '岩棉板') {
            item.sort = 2
          } else if (item.type === '复合板') {
            item.sort = 3
          } else if (item.type === '光板') {
            item.sort = 4
          } else if (item.type === '玻璃丝面板') {
            item.sort = 5
          } else {
            item.sort = 999
          }
        })
        list = list.sort((a, b) => a.sort - b.sort)
        const types = response.data.data.data
          .filter((v) => v.unit === '平方米')
          .map((item) => item.type);
        const uniqueTypes = [...new Set(types)];
        const groupByYear = response.data.data.data
          .filter((v) => v.unit === '平方米')
          .reduce((acc, curr) => {
            if (!acc[curr.year]) {
              acc[curr.year] = [];
            }
            acc[curr.year].push(curr);
            this.total3 += parseFloat(curr.value);
            return acc;
          }, {});
        const valuesByYear = Object.values(groupByYear).map((group) => {
          return group.map((item) => parseInt(item.value / 10000));
        });
        this.xDataLine3 = uniqueTypes;
        this.yDataLine3 = valuesByYear;

        const types1 = response.data.data.data
          .filter((v) => v.unit !== '平方米')
          .map((item) => item.type);
        const uniqueTypes1 = [...new Set(types1)];
        const groupByYear1 = response.data.data.data
          .filter((v) => v.unit !== '平方米')
          .reduce((acc, curr) => {
            if (!acc[curr.year]) {
              acc[curr.year] = [];
            }
            acc[curr.year].push(curr);
            this.total4 += parseFloat(curr.value);
            return acc;
          }, {});
        const valuesByYear1 = Object.values(groupByYear1).map((group) => {
          return group.map((item) => parseInt(item.value));
        });
        this.xDataLine4 = uniqueTypes1;
        this.yDataLine4 = valuesByYear1;
        this.$nextTick(() => {
          this.showBar = true;
        });
      } catch (e) {
        console.log(e);
      } finally {
        console.log('finish');
      }
    },
  },
  mounted() {
    this.handleGetDoorList();
    this.handleGetSalesList();
  },
};
</script>

<style lang='scss' scoped>
.page-container {
  background-image: url('../../assets/imgs/info-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  .charts-container {
    padding-top: 35px;
    width: calc(1920px * 0.95);
    margin-left: calc(1920px * 0.025);
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .chart-title {
      box-sizing: border-box;
      width: 100%;
      height: 44px;
      border: 1px solid #257fbe;
      box-shadow: 0 0 26px 5px rgba(#257fbe, 0.4) inset;
      padding: 0 15px;
      line-height: 42px;
      display: flex;
      justify-content: space-between;
      .area-name {
        font-size: 20px;
        color: #65c6e7;
      }
      .area-count {
        font-size: 18px;
        color: #65c6e7;
        span {
          color: #3edb07;
        }
      }
    }
    .area-content {
      width: 100%;
      height: calc(1080px - 44px - 35px - 475.2px - 90px - 66px - 35px);
      .pie3d {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      .bar-item {
        width: 50%;
        height: 100%;
        float: left;
      }
    }
    .chart-item {
      width: calc(1920px * 0.26);
      &:nth-child(2) {
        width: calc(1920px * 0.26);
      }
      &:nth-child(3) {
        width: calc(1920px * 0.41);
      }
    }
  }
}
</style>
